class CookiesHandler {
  constructor() {
    this.cookieCache = []
    this.cookies = {}
    this.loadCookies()
  }

  isCookiesAllowed() {
    return localStorage.getItem('allow_cookies') === 'true'
  }

  allowCookies() {
    localStorage.setItem('allow_cookies', 'true')
    this.cookieCache.forEach((cookie) => (document.cookie = cookie))
  }

  getAllCookies() {
    return this.cookies
  }

  loadCookies() {
    if (!this.isCookiesAllowed()) return

    const decodedCookies = decodeURIComponent(document.cookie)
    this.cookies = decodedCookies.split('; ').reduce((cookies, cookie) => {
      const [key, value] = cookie.split('=')
      return {
        ...cookies,
        [key]: value,
      }
    }, {})
  }

  getCookie(key) {
    if (!this.isCookiesAllowed()) return
    return this.cookies[key]
  }

  setCookie(key, value, expirationDate) {
    const cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
      value,
    )}; expires=${expirationDate}`
    if (!this.isCookiesAllowed()) {
      this.cookieCache.push(cookie)
      return
    }

    document.cookie = cookie
    this.loadCookies()
  }

  fillCookieGrantedInfo() {
    $('input[data-consent-granted]').val(this.isCookiesAllowed())
  }
}

export default CookiesHandler
